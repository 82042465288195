import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DynamicRedirectComponent } from '@core/dynamic-redirect.component';
import { Routes as AppRoutes } from '@core/enums/routes.enum';
import { REACH_DATA } from '@core/utils/reachData';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home-page/home-page.module').then((m) => m.HomePageModule),
  },
  {
    path: 'producto',
    loadChildren: () => import('./product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'productos',
    loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'armatupc',
    loadChildren: () => import('./arma-pc/arma-pc.module').then((m) => m.ArmaPcModule),
  },
  {
    path: 'ayuda',
    loadChildren: () =>
      import('./preguntas-frecuentes/preguntas-frecuentes.module').then(
        (m) => m.PreguntasFrecuentesModule,
      ),
  },
  {
    path: 'garantias',
    loadChildren: () => import('./warranties/warranties.route').then((r) => r.WARRANTIES_ROUTE),
  },
  {
    path: 'preg-frecuentes',
    loadChildren: () =>
      import('./preguntas-frecuentes/preguntas-frecuentes.module').then(
        (m) => m.PreguntasFrecuentesModule,
      ),
  },
  {
    path: 'terminos-condiciones',
    loadChildren: () =>
      import('./terminos-y-condiciones/terminos-y-condiciones.module').then(
        (m) => m.TerminosYCondicionesModule,
      ),
  },
  {
    path: 'carro-compras',
    loadChildren: () =>
      import('./shopping-cart/shopping-cart.module').then((m) => m.ShoppingCartModule),
  },
  {
    path: 'reserva-confirm',
    loadChildren: () =>
      import('./reserva-confirm/reserva-confirm.module').then((m) => m.ReservaConfirmModule),
  },
  {
    path: 'validar-mail',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'turnero',
    loadChildren: () => import('./turnero/turnero.module').then((m) => m.TurneroModule),
  },
  {
    path: AppRoutes.DEVOLUCION,
    loadChildren: () => import('@devolucion/devolucion.routes').then((r) => r.DEVOLUCION_ROUTES),
  },
  ...REACH_DATA.map((r) => ({
    path: r.name,
    redirectTo: `/productos?alcance=${r.name}`,
  })),
  {
    path: '**',
    component: DynamicRedirectComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
