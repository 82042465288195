import { CommonModule, NgStyle } from '@angular/common';
import { Component, OnInit, signal, TemplateRef, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EspacioSuperior } from '@core/models/piramide/EspacioSuperior';
import { PiramidalService } from '@core/services/piramidal.service';
import { ArrayOrder, sortProps } from '@core/utils/array.util';
import { todayInRange } from '@core/utils/date.util';
import { CarouselBaseComponent } from 'src/app/home-page/components/carousel-base/carousel-base.component';
import { CarouselItemBase } from 'src/app/home-page/components/carousel-base/carousel-item-base.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { take } from 'rxjs';
import { Autoplay } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'cgw-barra-superior',
  templateUrl: './barra-superior.component.html',
  styleUrls: ['./barra-superior.component.scss'],
  standalone: true,
  imports: [
    // Components
    CarouselBaseComponent,
    // Directives
    NgStyle,
    // Modules
    MatButtonModule,
    MatIconModule,
    NgxSkeletonLoaderModule,
    CommonModule,
  ],
})
export class BarraSuperiorComponent implements OnInit {
  private readonly _bannerTemplateRef = viewChild('bannerTemplate', { read: TemplateRef });

  public readonly hidden = signal<boolean>(false);
  public readonly carouselConfig = signal<SwiperOptions>({
    modules: [Autoplay],
    autoplay: {
      delay: 7000,
      pauseOnMouseEnter: true,
    },
    loop: true,
  });
  public readonly carouselItems = signal<CarouselItemBase<EspacioSuperior>[]>([]);
  public readonly loading = signal<boolean>(true);

  constructor(private piramidalService: PiramidalService) {}

  public ngOnInit(): void {
    this.loadBanners();
  }

  public hideBanners(): void {
    this.hidden.set(true);
  }

  private loadBanners(): void {
    this.loading.set(true);
    this.piramidalService.espacioSuperior.observable.pipe(take(1)).subscribe({
      next: (banners: EspacioSuperior[]) => {
        if (!banners || banners?.length <= 0) {
          this.loading.set(false);
          return;
        }

        const visibleBanners = banners.filter(
          (b) =>
            b.estado == 1 &&
            todayInRange(new Date(), new Date(b.fechaLanzamiento), new Date(b.fechaVencimiento)),
        );
        let withOrder = visibleBanners.filter((b) => b.orden > 0);
        let withoutOrder = visibleBanners.filter((b) => !b.orden || b.orden <= 0);

        withOrder = sortProps(withOrder, ['orden'], ArrayOrder.asc);
        withoutOrder = withoutOrder.sort(() => Math.random() - 0.5);

        this.carouselItems.set(
          [...withOrder, ...withoutOrder].map(
            (b) =>
              new CarouselItemBase({
                id: b.id,
                data: b,
                templateRef: this._bannerTemplateRef(),
                templateContext: { banner: b },
              }),
          ),
        );
      },
    });
    this.loading.set(false);
  }
}
