import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { environment } from '@environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppUpdatesService } from '@core/services/app-updates.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    MaterialModule,
    MglTimelineModule,
    LazyLoadImageModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.useServiceWorkers,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        inject(AppUpdatesService);
        return () => {};
      },
      deps: [AppUpdatesService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router) {
    if (environment.production) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          let ruta;
          if (event.urlAfterRedirects.includes('ayuda')) {
            ruta = 'Ayuda';
          } else if (event.urlAfterRedirects.includes('productos')) {
            ruta = 'Lista de productos';
          } else if (event.urlAfterRedirects.includes('producto')) {
            ruta = 'Detalle de producto';
          } else if (event.urlAfterRedirects == '/') {
            ruta = 'Home';
          } else if (event.urlAfterRedirects.includes('carro-compras')) {
            ruta = 'Carrito de compras';
          } else if (event.urlAfterRedirects.includes('cuenta')) {
            ruta = 'Mi cuenta';
          } else if (event.urlAfterRedirects.includes('reserva-confirm')) {
            ruta = 'Confirmación Pedido';
          }
        }
      });
    }
  }
}
