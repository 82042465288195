@if (!hidden() && carouselItems().length > 0) {
  <section class="upper-banners">
    <cgw-carousel-base
      [config]="carouselConfig()"
      [items]="carouselItems()">
    </cgw-carousel-base>

    <button
      class="upper-banners__close"
      mat-icon-button
      aria-label="Ocultar banners superiores"
      (click)="hideBanners()">
      <mat-icon svgIcon="cancel_full"></mat-icon>
    </button>
  </section>
}

<ng-template
  #bannerTemplate
  let-banner="banner">
  <div
    class="banner"
    [ngStyle]="{
      'background-color': !banner.codigoColor ? 'var(--colorPrimary)' : '#' + banner.codigoColor,
    }">
    <a
      [target]="banner.ventana == 1 ? '_blank' : '_self'"
      [href]="banner.link">
      <picture class="img">
        <source
          [srcset]="banner.nombreMobile || banner.nombre"
          media="(max-width: 689px)" />
        <source
          [srcset]="banner.nombreTablet || banner.nombre"
          media="(max-width: 1399px)" />
        <img
          class="banner__img"
          loading="lazy"
          [src]="banner.nombre"
          [alt]="banner.descripcion" />
      </picture>
    </a>
  </div>
</ng-template>
