import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, filter, map, of, switchMap, take } from 'rxjs';
import { PromosService } from './services/promos.service';

@Component({
  selector: 'cgw-dynamic-redirect',
  standalone: true,
  template: '<p>Redirigiendo...</p>',
})
export class DynamicRedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private promoService: PromosService,
  ) {}

  public ngOnInit(): void {
    const currentPath = window.location.pathname.replace('/', '');

    this.promoService.liquidations$
      .pipe(
        take(1),
        map((liquidations) => liquidations.map((liquidation) => liquidation.url)),
        filter((dynamicPromoRoutes) => dynamicPromoRoutes != undefined),
        switchMap((dynamicPromoRoutes) =>
          this.handleRouteNavigation(currentPath, dynamicPromoRoutes),
        ),
        catchError(() => {
          this.router.navigate(['/']);
          return of(null);
        }),
      )
      .subscribe();
  }

  private handleRouteNavigation(currentPath: string, routes: string[]) {
    if (routes.includes(currentPath)) {
      this.router.navigate(['/productos'], { queryParams: { liqui: currentPath } });
    } else {
      this.router.navigate(['/']);
    }
    return of(null);
  }
}
