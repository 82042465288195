<div [ngClass]="{ 'dark-theme': isDarkTheme | async }">
  <div class="sticky">
    <cgw-barra-superior></cgw-barra-superior>
    <cgw-header></cgw-header>
    <cgw-navbar class="navbar--desktop"></cgw-navbar>
  </div>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>
      <cgw-navbar class="navbar--mobile"></cgw-navbar>

      <div class="extra-height">
        <main
          role="main"
          class="container_height">
          <router-outlet
            #o="outlet"
            (activate)="onActivate($event)"></router-outlet>
        </main>
      </div>
      <cgw-footer></cgw-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <cgw-ui />
</div>
