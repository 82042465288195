<section class="ui-container">
  <div class="actions">
    <cgw-return-to-top />
  </div>

  <div class="notifications">
    @for (notification of notifications(); track notification.id) {
      <ng-container *ngComponentOutlet="notification.component; inputs: notification.inputs" />
    }
  </div>
</section>
