import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { INotificationReference } from '@core/interfaces/notification-reference.interface';
import { NotificationService } from '@core/services/notification.service';
import { NotificationComponent } from '../notification/notification/notification.component';
import { ReturnToTopComponent } from '../return-to-top/return-to-top.component';

@Component({
  selector: 'cgw-ui',
  standalone: true,
  templateUrl: './ui.component.html',
  styleUrl: './ui.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    // Directives
    NgComponentOutlet,
    // Components
    ReturnToTopComponent,
  ],
})
export class UiComponent {
  public get notifications(): Signal<INotificationReference<NotificationComponent>[]> {
    return NotificationService.notifications;
  }
}
