import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IconsService } from './services/icons.service';
import { ThemeService } from './services/theme.service';
import { ResponsiveService } from './services/responsive.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './services/userHandler/user.service';
import { PromosService } from './services/promos.service';
import { ShoppingCartService } from '../shopping-cart/services/shoppingCart.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'cgw-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent {
  isHandset: Observable<boolean> = this.responsiveService.isHandsetMobile$;
  isDarkTheme: Observable<boolean>;
  isLogin: boolean = false;
  public environment = environment;
  constructor(
    private responsiveService: ResponsiveService,
    private themeService: ThemeService,
    private iconService: IconsService,
    public route: Router,
    private userService: UserService,
    private _promoService: PromosService,
    private activatedRoute: ActivatedRoute,
    public _shoppingCartService: ShoppingCartService,
  ) {
    this._promoService.getLiquidations();
    this.userService.renovarToken().subscribe();
    this.activatedRoute.queryParams.subscribe((resQuery) => {
      if (resQuery.dark) {
        if (resQuery.dark === 'false') {
          this.themeService.setDarkTheme(false);
        } else {
          this.themeService.setDarkTheme(true);
        }
      } else if (resQuery.hash) {
        this.route.navigate(['validar-mail'], { queryParamsHandling: 'merge' });
      } else {
        return;
      }
    });
    this.isDarkTheme = this.themeService.isDarkTheme;
    this.iconService.init();
  }

  /*
    Esto es para que siempre vuelva hacia arriba cuando cambias de vista.
  */
  onActivate(event: any) {
    document.querySelector('mat-sidenav-content').scrollTop = 0;
  }
}
