import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductsService } from '@core/services/products.service';
import { ReachOutService } from '@core/services/reach-out.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { RouterService } from './core/services/router.service';

@Component({
  selector: 'cgw-root',
  template: '<cgw-core></cgw-core> ',
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription!: Subscription;
  constructor(
    private reachOutService: ReachOutService,
    private _productsService: ProductsService,
    private routerService: RouterService,
  ) {}

  public ngOnInit(): void {
    this._productsService.productsIdx$.pipe(take(1)).subscribe();
    this.reachOutService.getAllReaches();
  }

  public ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
