import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from '../material.module';
import { CoreComponent } from './core.component';
import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { JwtModule } from '@auth0/angular-jwt';
import { UserService } from './services/userHandler/user.service';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { BarraSuperiorComponent } from './components/barra-superior/barra-superior.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { UiComponent } from './components/ui/ui.component';

@NgModule({
  declarations: [CoreComponent],
  exports: [CoreComponent],
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    MglTimelineModule,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: UserService.obtenerToken,
        allowedDomains: [
          'localhost:5000',
          'localhost:5002',
          'https://desarrollo.compragamer.com/net_micro_web',
          'api.compragamer.com',
          'test.api.compragamer.com',
          'preproduccion.api.compragamer.com',
        ],
        //disallowedRoutes: ['localhost:8100/api/auth']
      },
    }),
    BarraSuperiorComponent,
    UiComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('El core ya se importó, solo puede importarse por el appModule');
    }
  }
}
