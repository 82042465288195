import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  inject,
  signal,
} from '@angular/core';
import { fadeOutAnimation } from '@core/animations/fade-out.animation';
import { zoomInAnimation } from '@core/animations/zoom-in.animation';
import { APP_CONST } from '@core/constants/app.const';
import { ViewportService } from '@core/services/viewport.service';
import { MaterialModule } from 'src/app/material.module';

@Component({
  selector: 'cgw-return-to-top',
  templateUrl: './return-to-top.component.html',
  styleUrls: ['./return-to-top.component.scss'],
  standalone: true,
  imports: [MaterialModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    style: 'align-self: flex-end;',
  },
  animations: [
    trigger('showHide', [
      state(
        'hide',
        style({
          visibility: 'hidden',
        }),
      ),
      transition('hide => show', [useAnimation(zoomInAnimation)]),
      transition('show => hide', [useAnimation(fadeOutAnimation)]),
    ]),
  ],
})
export class ReturnToTopComponent {
  //#region DI
  private readonly _viewportService = inject(ViewportService);
  //#endregion

  private readonly _shouldShow = signal<boolean>(false);

  @HostBinding('@showHide') get showHide(): 'show' | 'hide' {
    return this._shouldShow() ? 'show' : 'hide';
  }

  @HostListener('window:scroll')
  public onScroll(): void {
    const scrollYPos = this._viewportService.getScrollPosition()[1];
    this._shouldShow.set(scrollYPos > APP_CONST.scrollToTopOffset);
  }

  public goTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
